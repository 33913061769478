import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import PhotoThierryChatel from '../../global/photo-thierry-chatel';
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">

    <h4>{`DURÉE`}</h4>
    <h2>{`5 jours`}</h2>
    <br />
    <h4>{`PRÉ-REQUIS`}</h4>
    <p>{`Connaissance du développement web : HTML, CSS et JavaScript`}</p>
    <br />
    <h4>{`FORMATEUR`}</h4>
    <h2>{`Thierry Chatel`}</h2>
    <PhotoThierryChatel mdxType="PhotoThierryChatel" />
    <p>{`Consultant en architecture logicielle, spécialiste des applications web.`}</p>
    <p>{`Expert Angular reconnu par la communauté et par Google, nommé `}<em parentName="p">{`“Google Developer Expert”`}</em>{`
sur Angular en 2014, le premier en Europe.`}</p>
    <p>{`Conférencier réputé, et formateur très pédagogue ayant animé environ
150 formations à Angular.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      