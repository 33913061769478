import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <h2>{`Sessions intra entreprise`}</h2>
    <p>{`Si vous souhaitez former tout un groupe de développeurs ou architectes techniques de votre entreprise, le mieux est d’organiser
une session chez vous.`}</p>
    <p>{`Les sessions peuvent être organisées dans toute la France, ou à l’étranger pour un public francophone (Belgique,
Suisse, Luxembourg, etc.). L’effectif en intra entreprise est limité à 8 participants par session, qui connaissent
déjà le développement web.`}</p>
    <p>{`Prévoyez un délai d’environ un mois pour la disponibilité du formateur.`}</p>
    <table>
      <thead parentName="table">
        <tr parentName="thead">
          <th parentName="tr" {...{
            "align": null
          }}>{`CONTENU`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`DURÉE`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`TARIF POUR LE GROUPE (8 MAX.)`}</th>
        </tr>
      </thead>
      <tbody parentName="table">
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><strong parentName="td">{`Angular`}</strong></td>
          <td parentName="tr" {...{
            "align": null
          }}><strong parentName="td">{`5 jours`}</strong></td>
          <td parentName="tr" {...{
            "align": null
          }}><strong parentName="td">{`9000`}</strong>{` € HT frais de déplacement inclus`}<br /><em parentName="td">{`(7500 € HT sur Montpellier ou Nîmes)`}</em></td>
        </tr>
      </tbody>
    </table>
    <p><strong parentName="p">{`Pour toute demande d’information, merci d’envoyer un mail à `}<a parentName="strong" {...{
          "href": "mailto:tchatel@obi2b.com"
        }}>{`tchatel@obi2b.com`}</a></strong></p>
    <p><em parentName="p">{`Environ 150 sessions en intra entreprise réalisées à ce jour pour des entreprises de toutes tailles, à Montpellier, Sochaux, Genève, Lille, La Rochelle,
Bruxelles, Paris, Luxembourg, Saint-Etienne, Nîmes, Lyon, Tours, Roubaix, Aix-en-Provence, Bordeaux,
Nantes, Valence, Toulouse, Limoges, Tours, Grenoble, Pau, Angers, Marseille, Tunis, etc.`}</em></p>
    <h3>{`Formalités administratives`}</h3>
    <p>{`Les sessions intra entreprises sont organisées chez vous. Vous recevrez un devis et une convention de formation
professionnelle continue avec le programme de la formation, pour faire valider la prise en charge par votre OPCA.`}</p>
    <p>{`Numéro de déclaration d’activité de l’organisme de formation : 91-34-04871-34, auprès de la préfecture de la
région Languedoc-Roussillon.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      