import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <h2>{`Contenu de la formation Angular`}</h2>
    <p>{`100 % pratique en effectif réduit.`}</p>
    <p>{`La formation est toujours basée sur la dernière version stable du framework Angular.`}</p>
    <p>{`Le contenu ci-dessous liste les notions étudiées durant la formation. Ce n’est pas un
ordre chronologique, ça n’aurait aucun sens de traiter chaque partie isolément, alors
que de nombreuses notions sont nécessaires dans la moindre application Angular.`}</p>
    <ul>
      <li parentName="ul">
        <p parentName="li">{`TypeScript`}</p>
        <ul parentName="li">
          <li parentName="ul">{`Modules`}</li>
          <li parentName="ul">{`Block scope avec let et const`}</li>
          <li parentName="ul">{`Classes et interfaces`}</li>
          <li parentName="ul">{`Typage optionnel`}</li>
          <li parentName="ul">{`Arrow functions, sans impact sur la valeur de this`}</li>
          <li parentName="ul">{`Déstructuration`}</li>
          <li parentName="ul">{`Template strings`}</li>
          <li parentName="ul">{`Boucle for-of`}</li>
          <li parentName="ul">{`Paramètres : valeurs par défaut, rest, spread`}</li>
          <li parentName="ul">{`Décorateurs`}</li>
        </ul>
      </li>
      <li parentName="ul">
        <p parentName="li">{`Présentation d’Angular`}</p>
        <ul parentName="li">
          <li parentName="ul">{`Les concepts du framework, ses usages`}</li>
          <li parentName="ul">{`L’outillage pour le développement et le packaging d’une application Angular`}</li>
          <li parentName="ul">{`Le découpage d’une application en modules (introduction aux modules)`}</li>
        </ul>
      </li>
      <li parentName="ul">
        <p parentName="li">{`Composants`}</p>
        <ul parentName="li">
          <li parentName="ul">{`Syntaxe des templates, différents types de bindings et de directives`}</li>
          <li parentName="ul">{`Classe du composant, constructeurs, données et fonctions`}</li>
          <li parentName="ul">{`Component lifcycle`}</li>
          <li parentName="ul">{`Services et injection de dépendances`}</li>
          <li parentName="ul">{`Publication d’un service, via son provider`}</li>
          <li parentName="ul">{`L’injection de dépendances : type-based et hiérarchique`}</li>
          <li parentName="ul">{`Différents types de providers`}</li>
        </ul>
      </li>
      <li parentName="ul">
        <p parentName="li">{`Routage`}</p>
        <ul parentName="li">
          <li parentName="ul">{`Le component router, sa mise en place et son fonctionnement`}</li>
          <li parentName="ul">{`Définitions des routes, liens et redirection, paramètres`}</li>
          <li parentName="ul">{`Hiérarchies de routes`}</li>
          <li parentName="ul">{`Routing lifecycle, et route guards`}</li>
        </ul>
      </li>
      <li parentName="ul">
        <p parentName="li">{`Modules`}</p>
        <ul parentName="li">
          <li parentName="ul">{`Déclarations du module, imports et exports`}</li>
          <li parentName="ul">{`Lazy loading de modules avec le component router`}</li>
          <li parentName="ul">{`Les providers d’un module, et cas d’un module en lazy loading`}</li>
          <li parentName="ul">{`Différents types de modules, bonnes et mauvaises pratiques`}</li>
        </ul>
      </li>
      <li parentName="ul">
        <p parentName="li">{`Tests`}</p>
        <ul parentName="li">
          <li parentName="ul">{`Tests unitaires avec Karma et Jasmine`}</li>
          <li parentName="ul">{`Tests fonctionnels (E2E) avec Protractor`}</li>
        </ul>
      </li>
      <li parentName="ul">
        <p parentName="li">{`RxJS`}</p>
        <ul parentName="li">
          <li parentName="ul">{`Le concept d’Observable, principales opérations sur les observables`}</li>
          <li parentName="ul">{`Les opérateurs de RxJS`}</li>
          <li parentName="ul">{`Comparaison avec les promesses`}</li>
        </ul>
      </li>
      <li parentName="ul">
        <p parentName="li">{`HTTP et authentification`}</p>
        <ul parentName="li">
          <li parentName="ul">{`Faire des requêtes HTTP, recevoir ou envoyer des données JSON`}</li>
          <li parentName="ul">{`Gestion de l’authentification`}</li>
        </ul>
      </li>
      <li parentName="ul">
        <p parentName="li">{`Pipes`}</p>
        <ul parentName="li">
          <li parentName="ul">{`Formatage avec des pipes Angular`}</li>
          <li parentName="ul">{`Les pipes fournis avec le framework`}</li>
          <li parentName="ul">{`Création de ses propres pipes`}</li>
        </ul>
      </li>
      <li parentName="ul">
        <p parentName="li">{`Fonctionnement interne`}</p>
        <ul parentName="li">
          <li parentName="ul">{`Compilation des templates`}</li>
          <li parentName="ul">{`Le langage d’expressions d’Angular`}</li>
          <li parentName="ul">{`Rafraîchissement des vues : en cascade, et un seul passage`}</li>
          <li parentName="ul">{`Zone.js`}</li>
        </ul>
      </li>
      <li parentName="ul">
        <p parentName="li">{`Formulaires`}</p>
        <ul parentName="li">
          <li parentName="ul">{`Template-driven forms`}</li>
          <li parentName="ul">{`Reactive forms`}</li>
          <li parentName="ul">{`Validation des formulaires`}</li>
          <li parentName="ul">{`Formulaires dynamiques`}</li>
        </ul>
      </li>
      <li parentName="ul">
        <p parentName="li">{`Création de directives`}</p>
        <ul parentName="li">
          <li parentName="ul">{`Attribute directives, fonctionnement et création`}</li>
          <li parentName="ul">{`Structural directives, fonctionnement et création`}</li>
        </ul>
      </li>
      <li parentName="ul">
        <p parentName="li">{`Animations`}</p>
        <ul parentName="li">
          <li parentName="ul">{`Etats et transitions`}</li>
          <li parentName="ul">{`Animations avec le routeur`}</li>
        </ul>
      </li>
      <li parentName="ul">
        <p parentName="li">{`Autour d’Angular`}</p>
        <ul parentName="li">
          <li parentName="ul">{`Bibliothèques de composants`}</li>
          <li parentName="ul">{`Server-side rendering avec Angular Universal`}</li>
        </ul>
      </li>
    </ul>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      