import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <p>{`Formation complète de 5 jours à Angular, le framework JavaScript créé par Google, le plus utilisé en France
pour développer des applications Web.`}</p>
    <p>{`Animée par `}<strong parentName="p">{`Thierry Chatel`}</strong>{`, premier `}<em parentName="p">{`Google Developer Expert`}</em>{` sur Angular en Europe, et formateur
le plus expérimenté sur le sujet. `}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      