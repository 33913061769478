import * as React from 'react';
import { graphql, useStaticQuery } from 'gatsby';
import Img from 'gatsby-image';
import './photo-rounded.scss';

const PhotoThierryChatel = () => {
  const data = useStaticQuery(graphql`
    query {
      image: file(relativePath: { eq: "global/photo-thierry-chatel.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 200) {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `)
  return (
    <div className="photo-rounded">
      <Img fluid={data.image.childImageSharp.fluid} />
    </div>
  );
};

export default PhotoThierryChatel;
