import * as React from 'react';
import './formation-layout.scss';

const FormationLayout = ({ children }) => (
  <div className="formation-layout">
    { children }
  </div>
);

export default FormationLayout;

