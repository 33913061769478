import SEO from "../../../../src/components/global/seo";
import FormationImage from "../../../../src/components/formation/angular/image";
import FormationLayout from "../../../../src/components/formation/formation-layout";
import FormationHeader from "../../../../src/components/formation/angular/header.mdx";
import FormationMain from "../../../../src/components/formation/angular/main.mdx";
import FormationProgram from "../../../../src/components/formation/angular/program.mdx";
import FormationTrainer from "../../../../src/components/formation/angular/trainer.mdx";
import * as React from 'react';
export default {
  SEO,
  FormationImage,
  FormationLayout,
  FormationHeader,
  FormationMain,
  FormationProgram,
  FormationTrainer,
  React
};